export const DEFAULT_SONG = "at-the-beach";

export const hawaiian = {
    "Aloha Week Hula": "aloha-week-hula",
    "Hula Breeze": "hula-breeze",
    Kainoa: "kainoa",
    "Koke'e": "kokee",
    "Mauna Chimes": "mauna-chimes",
    Pohakuloa: "pohakuloa",
    Seabird: "seabird",
    "White Sandy Beach": "white-sandy-beach-1",
};

export const originals = {
    "At The Beach": "at-the-beach",
    "At The Beach v. 2": "at-the-beach-1",
    "A Change of Heart": "a-change-of-heart-aug-2017",
    Domino: "domino-170",
    "Flying Harmonicas": "flying-harmonicas-1",
    "In This Light You Look Like a Friend of Mine":
        "in-this-light-you-look-like-a-friend-of-mine",
    "Sprayed By Skunk v. 3": "sprayed-by-skunk-3",
    Sunflower: "sunflower",
    Superhero: "superhero-6-28-2020",
};

export const covers = {
    "Between the Devil and the Deep Blue Sea":
        "between-the-devil-and-the-deep-blue-sea",
    "City of Stars": "city-of-stars-steve",
    "Slow Down": "slow-down",
    "Sunshine On My Shoulders": "sunshine-on-my-shoulders",
    "Sweet Child of Mine (Steel Guitar)": "sweet-child-of-mine-steel",
    "Thank You Girl": "thank-you-girl",
    "Will You Still Love Me Tomorrow (Dance Version)":
        "will-you-still-love-me-tomorrow-dance-version",
};

export const collabs = {};
